import React from 'react';

function clientRandomizer(props) {
  const millsAds = [
    {
      image: '/sponsors/double-matching.jpg',
      link: 'https://usuo.org/donate/',
      alt: 'Utah Opera',
      class: 'webFormat',
    },
    {
      image: '/sponsors/opera-live.jpg',
      link: 'https://www.utahopera.com/',
      alt: 'Utah Opera',
      class: 'webFormat',
    },
    {
      image: '/sponsors/daynes.jpg',
      link: 'https://www.daynesmusic.com/',
      alt: 'Daynes Music',
      class: 'webFormat',
    },
    {
      image: '/sponsors/marketing-one.jpg',
      link: 'https://utahsymphony.org/',
      alt: 'Add some symphony to your life',
      class: 'webFormat',
    },
    {
      image: '/sponsors/marketing-two.jpg',
      link: 'https://utahsymphony.org/plan-your-visit/app',
      alt: 'Get the new Utah Symphony app for iphone or android',
      class: 'webFormat',
    },
    {
      image: '/sponsors/marketing-three.jpg',
      link: 'https://utahsymphony.org/das',
      alt: 'Utah Symphony Design-A-Series',
      class: 'webFormat',
    },
    {
      image: '/sponsors/marketing-four.jpg',
      link: 'https://utahsymphony.org/store',
      alt: 'Utah Symphony Gift Store',
      class: 'webFormat',
    },
    {
      image: '/sponsors/marketing-five.jpg',
      link: 'https://utahsymphony.org/plan-your-visit/app',
      alt: 'Utah Symphony Ghost Light Podcast',
      class: 'webFormat',
    },
    {
      image: '/sponsors/pbs-utah.jpg',
      link: 'https://pbsutah.org',
      alt: 'PBS Utah',
      class: 'webFormat',
    },
    {
      image: '/sponsors/volunteer.jpg',
      link: 'https://utahsymphony.org/support/volunteer',
      alt: 'Volunteer for the Utah Symphony',
      class: 'webFormat',
    },
    {
      image: '/sponsors/classical-89.jpg',
      link: 'https://www.classical89.org',
      alt: 'Classical 89',
      class: 'webFormat',
    },
    {
      image: '/sponsors/little-america.jpg',
      link:
        'https://saltlake.littleamerica.com/?_ga=2.73162259.1070999778.1609795541-919249251.1609795541',
      alt: 'Little America Hotel',
      class: 'webFormat',
    },
    {
      image: '/sponsors/grand-america.jpg',
      link: 'https://www.grandamerica.com/',
      alt: 'Grand America Hotel',
      class: 'webFormat',
    },
  ];

  const randomAd = millsAds[Math.floor(Math.random() * millsAds.length)];

  return (
    <>
      <hr />
      <br />
      <a href={randomAd.link} target="_blank" rel="noopener noreferrer">
        <img
          src={randomAd.image}
          alt={randomAd.alt}
          className={randomAd.class}
        />
      </a>
      <br />
      <br />
      <hr />
    </>
  );
}

export default clientRandomizer;
