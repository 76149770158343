import React from 'react';

function Purchase(props) {
  return (
    <>
      <ul id="home-list" style={{ listStyle: 'none' }}>
        <a
          href="https://usuo.org/on-demand/?utm_source=Online%20Playbill"
          target="_blank"
          rel="noopener noreferrer"
        >
          <li
            style={{
              backgroundColor: 'firebrick',
              fontFamily: 'sans-serif',
              fontWeight: 'normal',
            }}
          >
            🎟 PURCHASE ON DEMAND TICKETS
          </li>
        </a>
      </ul>
    </>
  );
}

export default Purchase;
