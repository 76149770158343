import React from 'react';
import { OutboundLink } from 'gatsby-plugin-google-analytics';

function Randomizer(props) {
  const millsAds = [
    {
      image: '/ads/william-call.png',
      link:
        'https://www.williamcall.net/?utm_source=Mills%20Publishing&utm_medium=mobile%20publication&utm_campaign=Revolutions%20Beatles%20Tribute',
      alt: 'William Call',
      class: 'webFormat',
    },
    {
      image: '/ads/rubys-inn.jpg',
      link:
        'https://www.rubysinn.com/?utm_source=mills%20publishing%20utah%20symphony&utm_medium=banner&utm_campaign=redefine_family_time',
      alt: 'Rubys Inn',
      class: 'webFormat',
    },
    {
      image: '/ads/university-credit-union.jpg',
      link:
        'https://www.ucreditu.com/?utm_source=Mills%20Publishing&utm_medium=mobile%20publication&utm_campaign=ondemand',
      alt: 'University Credit Union',
      class: 'webFormat',
    },
    {
      image: '/ads/challenger.jpg',
      link:
        'https://www.challengerschool.com/?utm_source=Mills%20Publishing&utm_medium=digital%20program&utm_campaign=ondemand',
      alt: 'Challenger School',
      class: 'webFormat',
    },
    {
      image: '/ads/dixie-state-university.jpg',
      link:
        'https://dixie.edu/?utm_source=Mills%20Publishing&utm_medium=digital%20program&utm_campaign=ondemand',
      alt: 'Dixie State University',
      class: 'webFormat',
    },
    {
      image: '/ads/rc-willey.jpg',
      link:
        'https://www.rcwilley.com/?utm_source=Mills%20Publishing&utm_medium=digital%20program&utm_campaign=ondemand',
      alt: 'RC Willey',
      class: 'webFormat',
    },
    {
      image: '/ads/utah-shakespeare-festival.jpg',
      link:
        'https://www.bard.org/?utm_source=Mills%20Publishing&utm_medium=digital%20program&utm_campaign=ondemand',
      alt: 'Utah Shakespeare Festival',
      class: 'webFormat',
    },
  ];

  const [randomAd] = React.useState(
    millsAds[Math.floor(Math.random() * millsAds.length)]
  );

  return (
    <>
      <hr />
      <br />
      <OutboundLink
        href={randomAd.link}
        target="_blank"
        rel="noopener noreferrer"
      >
        <img
          src={randomAd.image}
          alt={randomAd.alt}
          className={randomAd.class}
        />
      </OutboundLink>
      <br />
      <br />
      <hr />
      <br />
    </>
  );
}

export default Randomizer;
