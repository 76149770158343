import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsx mdx */

import Randomizer from '../components/randomizer';
import ClientRandomizer from '../components/clientrandomizer';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faVolumeMute } from '@fortawesome/free-solid-svg-icons';
import { Link } from 'gatsby';
import Purchase from '../components/purchase';
import Zoom from 'react-reveal/Zoom'; // Importing Zoom effect

import AniLink from 'gatsby-plugin-transition-link/AniLink';
export const _frontmatter = {};
const layoutProps = {
  _frontmatter
};
const MDXLayout = "wrapper";
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">

    <img {...{
      "src": "/on-demand.svg",
      "alt": "On Demand"
    }}></img>
    <h2>Welcome.</h2>
    <Randomizer mdxType="Randomizer" />
    <Purchase mdxType="Purchase" />
    <p>{`📱 Please use the menu to explore our new mobile friendly program.`}</p>
    <AniLink cover bg="#2E3440" to="/concert/songs" style={{
      textDecoration: 'none'
    }} mdxType="AniLink">
      <Zoom mdxType="Zoom">
  <div className="mills-card">
          <p><strong parentName="p">{`On Demand Series`}</strong></p>
          <hr />
          <h2 {...{
            "id": "songs-of-our-time",
            "style": {
              "position": "relative"
            }
          }}><a parentName="h2" {...{
              "href": "#songs-of-our-time",
              "aria-label": "songs of our time permalink",
              "className": "anchor before"
            }}><svg parentName="a" {...{
                "aria-hidden": "true",
                "focusable": "false",
                "height": "16",
                "version": "1.1",
                "viewBox": "0 0 16 16",
                "width": "16"
              }}><path parentName="svg" {...{
                  "fillRule": "evenodd",
                  "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
                }}></path></svg></a><strong parentName="h2">{`Songs of Our Time`}</strong></h2>
          <p><em parentName="p">{`Available April 28–May 28, 2021`}</em></p>
        </div>
      </Zoom>
    </AniLink>
    <AniLink cover bg="#2E3440" to="/concert/rodrigo" style={{
      textDecoration: 'none'
    }} mdxType="AniLink">
      <Zoom mdxType="Zoom">
  <div className="mills-card">
          <p><strong parentName="p">{`On Demand Series`}</strong></p>
          <hr />
          <h2 {...{
            "id": "pablo-sáinz-villegas-plays-rodrigo",
            "style": {
              "position": "relative"
            }
          }}><a parentName="h2" {...{
              "href": "#pablo-s%C3%A1inz-villegas-plays-rodrigo",
              "aria-label": "pablo sáinz villegas plays rodrigo permalink",
              "className": "anchor before"
            }}><svg parentName="a" {...{
                "aria-hidden": "true",
                "focusable": "false",
                "height": "16",
                "version": "1.1",
                "viewBox": "0 0 16 16",
                "width": "16"
              }}><path parentName="svg" {...{
                  "fillRule": "evenodd",
                  "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
                }}></path></svg></a><strong parentName="h2">{`Pablo Sáinz Villegas Plays Rodrigo`}</strong></h2>
          <p><em parentName="p">{`Available April 13–May 12, 2021`}</em></p>
        </div>
      </Zoom>
    </AniLink>
    <ClientRandomizer mdxType="ClientRandomizer" />

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      